'use strict';

angular.module('cpformplastApp.inventory')
    .controller('locationCreationController', function($scope, appConfig, Modal, DataManager, $state, Notification) {

  $scope.location = {
    content: 'all'
  };
  $scope.zones = [];
  $scope.contents = [
    { value: 'Les deux', rawValue: 'all'},
    { value: 'Matière première', rawValue: 'raw-material'},
    { value: 'Produit fini', rawValue: 'finished-product'}
  ];
  $scope.submitted = false;

  $scope.init = function() {
    $scope.zones = appConfig.zones;
    if ($state.params.locationId) {
      DataManager.fetchLocation($state.params.locationId).then(function(location){
        $scope.location = location;
      },function(error){
        console.log(error);
      });
    }
  };

  $scope.submitLocation = function(form, create) {

    $scope.submitted = true;
    if (!form.$valid || !$scope.location.zone) {
      return false;
    }

    var promise ;
    if (!$scope.location._id) {
      promise = DataManager.createLocation($scope.location);
    } else {
      promise = DataManager.updateLocation($scope.location);
    }

    promise.then((data) => {

      $scope.location._id = data._id;
      $state.params.locationId = data._id;

      Notification.success("L'emplacement <strong>" + $scope.location.zone + $scope.location.number + "</strong> fut enregistré");

      if (create) {
        $state.go('inventory/locations/location-creation', {'locationId' : ''}, {reload:true});
      } else {
        $state.go('inventory/locations/location-creation', {'locationId' : $scope.location._id}, {reload:true});
      }
    })
    .catch(err => {
      Notification.error('Un problème est survenu');
    });

  };

  $scope.getContentValue = function() {
    return $scope.contents.find(content => content.rawValue === $scope.location.content).value;
  };

  $scope.init();
});
